import { BrandDocument, brandSchema } from '@models/brand';
import { db } from '@util/firebase';
import { logError } from '@util/logError';
import { BrandProp } from 'app/brands/components/Brands';
import {
  CollectionReference,
  collection,
  doc,
  getDocs,
  getDocsFromCache,
  limit,
  loadBundle,
  namedQuery,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { getStorage, ref, uploadString } from 'firebase/storage';

const fetchBundle = async () => {
  try {
    const url = process.env.NEXT_PUBLIC_IS_STAGING
      ? 'https://storage.googleapis.com/mx-locker-staging.appspot.com/common-files/brand-bundle.txt'
      : 'https://bundle.mxlocker.com/brand-bundle.txt';
    const res = await fetch(url);
    const text = await res.text();
    if (typeof window !== 'undefined') await loadBundle(db, text);
  } catch (e) {
    logError(e, 'brandBundle');
  }
};

export const fetchBrandsFromCDN = async () => {
  const url = process.env.NEXT_PUBLIC_IS_STAGING
    ? 'https://storage.googleapis.com/mx-locker-staging.appspot.com/common-files/brand-bundle.json'
    : 'https://bundle.mxlocker.com/brand-bundle.json';
  const res = await fetch(url, {
    headers: {
      'content-type': 'application/json',
    },
  });
  const obj: { 'brands-query': BrandDocument[] } = await res.json();
  return obj['brands-query'];
};

// FOR COST OPTIMIZATION: Do not extend namedQuery or else it will make the firestore call. TODO Before May 2025
// so like brands for example, the bundle is cached, but if i extend the query to query by url, it makes the firestore call
// 12:42
// Correct:
// async getBrandBySlug(slug: string) {
//     const nQuery = await namedQuery(this.db, "brands-query");
//     const { docs } = await getDocsFromCache(nQuery as Query<Brand>);
//     const brands = docs.map((doc) => doc.data());
//     return brands.find((b) => b.url === slug);
//   }
// 12:43
// Incorrect:
// async getBrandBySlug(slug: string) {
//     const nQuery = await namedQuery(this.db, "brands-query");
//     const q = query(nQuery, where("url", "==", slug), limit(1)) as Query<Brand>;
//     const snap = await getDocs(q);
//     if (snap.empty) return null;
//     return snap.docs[0].data();
//   }
export async function getBrands(): Promise<BrandProp[]> {
  if (process.env.NODE_ENV === 'production')
    try {
      if (typeof window !== 'undefined') {
        const nQuery = await namedQuery(db, 'brands-query');
        if (nQuery) {
          const { docs } = await getDocsFromCache(nQuery);
          const brands = docs.map((d) => d.data());
          return brands as BrandDocument[];
        } else fetchBundle();
      }
      return await fetchBrandsFromCDN();
    } catch (e) {
      console.error(e);
      const ref = collection(
        db,
        'brands'
      ) as CollectionReference<BrandDocument>;
      const q = query(ref, orderBy('name'));
      const snap = await getDocs(q);
      const brands = snap.docs.map((d) => d.data());
      return brands;
    }
  else {
    const ref = collection(db, 'brands') as CollectionReference<BrandDocument>;
    const q = query(ref, orderBy('name'));
    const snap = await getDocs(q);
    const brands = snap.docs.map((d) => d.data());
    return brands;
  }
}

export async function getBrandBySlug(slug: string) {
  try {
    if (typeof window !== 'undefined') {
      const nQuery = await namedQuery(db, 'brands-query');
      if (nQuery) {
        const q = query(nQuery, where('url', '==', slug), limit(1));
        const { docs } = await getDocsFromCache(q);
        const brands = docs.map((d) => d.data());
        return brands[0] as BrandDocument;
      } else fetchBundle();
    }
    const brands = await fetchBrandsFromCDN();
    return brands.find((b) => b.url === slug) ?? null;
  } catch (e) {
    console.error(e);
    const ref = collection(db, 'brands') as CollectionReference<BrandDocument>;
    const q = query(ref, where('url', '==', slug), limit(1));
    const snap = await getDocs(q);
    return snap.docs[0]?.data() ?? null;
  }
}

export async function getBrandByName(name: string) {
  try {
    if (typeof window !== 'undefined') {
      const nQuery = await namedQuery(db, 'brands-query');
      if (nQuery) {
        const q = query(nQuery, where('name', '==', name), limit(1));
        const { docs } = await getDocsFromCache(q);
        const brands = docs.map((d) => d.data());
        if (brands[0]) return brands[0] as BrandDocument;
        return null;
      } else fetchBundle();
    }
    const brands = await fetchBrandsFromCDN();
    return brands.find((b) => b.name === name) ?? null;
  } catch (e) {
    console.error(e);
    const ref = collection(db, 'brands') as CollectionReference<BrandDocument>;
    const q = query(ref, where('name', '==', name), limit(1));
    const snap = await getDocs(q);
    return snap.docs[0]?.data() ?? null;
  }
}

export async function createBrand(brand: BrandDocument) {
  const parsedBrand = brandSchema.parse(brand);
  const brandRef = collection(db, 'brands');
  const docRef = doc(brandRef);
  await setDoc(docRef, parsedBrand);
}

export async function updateBrand(brand: BrandDocument) {
  const nQuery = await namedQuery(db, 'brands-query');
  let id: string | null = null;
  if (nQuery) {
    const q = query(nQuery, where('url', '==', brand.url), limit(1));
    const { docs } = await getDocsFromCache(q);
    id = docs[0]?.id;
  } else {
    fetchBundle();
    const colRef = collection(db, 'brands');
    const q = query(colRef, where('url', '==', brand.url), limit(1));
    const { docs } = await getDocs(q);
    id = docs[0]?.id;
  }
  if (!id) {
    throw new Error('Brand not found');
  }
  const brandRef = doc(db, 'brands', id);
  const parsedBrand = brandSchema.parse(brand);
  await setDoc(brandRef, parsedBrand);
}

export async function uploadBrandImage(image: string, url: string) {
  const storageRef = getStorage();
  const path = `brands/${url}`;
  const brandStorageRef = ref(storageRef, path);
  const res = await uploadString(brandStorageRef, image, 'data_url');
  return res.metadata.fullPath;
}

// Deprecated
// export async function getBrandsByCategory(
//   mainCat: string,
//   cat1: string | null
// ) {
//   const brandCatsRef = collection(db, 'brand_cats');
//   const ref = doc(brandCatsRef, mainCat);
//   const docSnap = await getDoc(ref);
//   const brandCats = docSnap.data() as {
//     brands: string[];
//     cat1s: { [key: string]: string[] };
//   };
//   let finalBrands: string[] = [];
//   if (!brandCats) return finalBrands;
//   const brands = brandCats.brands;
//   const cat1s = brandCats.cat1s;
//   if (!cat1) {
//     finalBrands = brands;
//   } else {
//     const cat1Brands = cat1s[cat1];
//     if (cat1Brands) {
//       finalBrands = cat1Brands.concat(brands);
//     }
//   }
//   return [...new Set(finalBrands)].sort();
// }
