import { doc, getDoc } from '@firebase/firestore';
import { AddressDocument } from '@models/address';
import { logAddToCart, logEvent } from '@util/analytics';
import { FirebaseCallable, db, functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';
import { Cart, CartItem, cartSchema } from '@util/types/firestore/carts';
import { europeCodes, southAmCodes } from '@util/types/locations';
import { setDoc } from 'firebase/firestore';
import { ProductDocument } from 'models/product';
import { UserDocument } from 'models/user';
import { trackUserInteraction } from '../recommendations';
import { getTax } from '../taxjar';
import { GetCartArgs, SetCartArgs } from './cart.types';
import {
  OrderDocument,
  OrderInput,
  OrderItemDocument,
  orderSchema,
} from 'models/order';
import { cartItemToOrderItem } from '@util/index';
import { Coupon } from 'models/coupon';

export function getDefaultCart(uid: string) {
  return cartSchema.parse({ uid });
}

export async function getCartState(args: GetCartArgs): Promise<Cart | null> {
  if (!args.uid) return null;
  try {
    const snapshot = await getDoc(doc(db, 'carts', args.uid));
    if (!snapshot.exists()) {
      const cart = getDefaultCart(args.uid);
      await setCartState({ newCartState: cart });
      return cart;
    }
    return snapshot.data() as Cart;
  } catch (e) {
    logError(e);
    // TODO: maybe try to recover cart data instead of just creating a new one
    const cart = getDefaultCart(args.uid);
    // setCartState({ newCartState: cart }).catch((e) =>
    // logError(e, 'setCartState')
    // );
    return cart;
  }
}

export async function setCartState(args: SetCartArgs) {
  await setDoc(doc(db, 'carts', args.newCartState.uid), args.newCartState);
}

export async function logCart(
  item: CartItem,
  product: ProductDocument | null,
  uid: string
) {
  try {
    if (product) {
      const total =
        (item.shipping_cost || 0) + item.product_cost * (item.qty || 1);
      await logAddToCart(product, total, uid ?? '');
      await trackUserInteraction({
        uid: uid ?? '',
        pid: product?.id ?? '',
        interaction: 'add_to_cart',
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function calculateShipping(product: ProductDocument, region: string) {
  const cost = product?.shipping_costs?.find((s) => {
    if (region === 'AK') return s.code === 'AK' ? s : false;
    if (region === 'HI') return s.code === 'HI' ? s : false;
    if (region === 'PR') return s.code === 'PR' ? s : false;
    if (s.code === region) return s;
    if (s.code === 'EU' && europeCodes.includes(region)) return s;
    if (s.code === 'SAM' && southAmCodes.includes(region)) return s;
    return false;
  });

  if (!cost) {
    // find OT shipping
    const ot = product.shipping_costs?.find((o) => o.code === 'OT');
    if (ot) return ot;
  }
  return cost;
}
export function getShippingRegion(address: AddressDocument) {
  if (address.state_province === 'AK') return 'AK';
  if (address.state_province === 'HI') return 'HI';
  if (address.state_province === 'PR') return 'PR';
  if (europeCodes.includes(address.country_code)) return 'EU';
  if (southAmCodes.includes(address.country_code)) return 'SAM';
  return address.country_code;
}

const calcFlatRateShipping = (
  productId: string,
  address: AddressDocument,
  products: ProductDocument[]
) => {
  const foundProduct = products.find((r) => r.id === productId);
  if (!foundProduct) return;
  return calculateShipping(foundProduct, getShippingRegion(address));
};

export function getDiscount(
  total: number,
  coupons: Coupon[] = [],
  items: CartItem[]
) {
  if (!coupons.length) return 0;
  // only support one coupon for now
  const coupon = coupons[0];

  if (coupon.amount_off) {
    if (coupon.account_id) {
      const affectedItems = items.filter(
        (i) => i.account_id === coupon.account_id
      );
      const affectedItemsTotal = affectedItems.reduce(
        (acc, item) => acc + item.qty * item.product_cost,
        0
      );
      return Math.min(affectedItemsTotal, coupon.amount_off);
    }
    return coupon.amount_off;
  }
  if (coupon.percent_off) {
    if (coupon.account_id) {
      const affectedItems = items.filter(
        (i) => i.account_id === coupon.account_id
      );
      const affectedItemsTotal = affectedItems.reduce(
        (acc, item) => acc + item.qty * item.product_cost,
        0
      );
      return affectedItemsTotal * (coupon.percent_off / 100);
    }
    const percentOff = coupon.percent_off;
    return total * (percentOff / 100);
  }

  return 0;
}

export function getCartItemsSubtotal(items: CartItem[], coupons?: Coupon[]) {
  const total = items.reduce(
    (accumulator, cartItem) =>
      accumulator + cartItem.qty * cartItem.product_cost,
    0
  );
  const discount = getDiscount(total, coupons ?? [], items);
  return Math.max(1, total - discount);
}

export const getShipping = ({
  items,
  products,
  shipping_address,
  userDoc,
}: {
  items: CartItem[];
  products: ProductDocument[];
  shipping_address?: AddressDocument;
  userDoc?: UserDocument;
}) => {
  let shippingTotal = 0;
  if (!shipping_address) return { shippingTotal };
  items.forEach((i) => {
    if (i.is_flat_rate) {
      const flatRate = calcFlatRateShipping(
        i.product_id,
        shipping_address,
        products ?? []
      );
      if (!flatRate) {
        logEvent(
          'shipping_unavailable',
          {
            items: products,
            currency: 'USD',
          },
          userDoc?.uid
        );
        delete i.shipping_cost;
        delete i.shipping_region;
        return;
      }
      i.shipping_cost = flatRate.cost;
      i.shipping_region = flatRate.code;
      shippingTotal += flatRate.cost ?? 0;
    } else {
      shippingTotal += i.shipping_cost ?? 0;
    }
  });
  return { shippingTotal };
};

export async function checkRaEligibility(order: OrderDocument) {
  return await httpsCallable<
    { order?: OrderDocument },
    { is_eligible: boolean; reason: string; fee: number }
  >(
    functions,
    FirebaseCallable.checkRaEligibility
  )({ order });
}

export function getOrderDocFromCartForRA({
  cart,
  userDoc,
}: {
  cart?: Cart | null;
  userDoc?: UserDocument | null;
}): OrderDocument | null {
  if (!cart || !userDoc) return null;
  try {
    const input: OrderInput = {
      address:
        cart.shipping_address ??
        userDoc.addresses.find((a) => a.is_default) ??
        userDoc?.addresses[0],
      created: Date.now(),
      id: cart.uid, // tmp
      total: getCartItemsSubtotal(cart.items),
      buyer_id: userDoc.uid,
      sellers: cart.items.reduce((acc, item) => {
        if (acc[item.seller_id]) {
          acc[item.seller_id].push(cartItemToOrderItem(item));
        } else {
          acc[item.seller_id] = [cartItemToOrderItem(item)];
        }
        return acc;
      }, {} as Record<string, OrderItemDocument[]>),
      product_ids: cart.items.map((i) => i.product_id),
      seller_arr: Array.from(new Set(cart.items.map((i) => i.seller_id))),
    };
    const orderDoc = orderSchema.parse(input);
    return orderDoc;
  } catch (e) {
    return null;
  }
}
